import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { WXDJ, UnifiedResponse } from './common';
import { BackendService } from './services/backend.service';
import { ToastService } from 'ngx-weui';

@Injectable({
  providedIn: 'root'
})
export class InitOpenidGuard implements CanActivate {
  constructor(
    private backendService: BackendService,
    private toastSrv: ToastService
  ) {
    this.toastSrv.loading("数据加载中...", 0)
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (WXDJ.isDebug) {
      WXDJ.open_id = 'okTiK5_FndaHvqTKTYv4CfXEhjZY';
      WXDJ.account_id = 1;
      this.toastSrv.hide();
      return true;
    }

    let code = next.queryParamMap.get("code") || '';
    let open_id = next.queryParamMap.get('open_id') || '';
    if (!code && !open_id) {
      let url = new URL(location.href);
      code = url.searchParams.get('code') || '';
      open_id = url.searchParams.get('open_id') || '';
    }

    return new Observable<boolean>(
      observer => {
        if (!code && !open_id) {
          open_id = window.sessionStorage.getItem('open_id') || '';
        }

        this.initUserInfo(code, open_id).then(() => {
          this.toastSrv.hide();
          window.sessionStorage.setItem('open_id', WXDJ.open_id);
          observer.next(true);
        })
          .catch(res => {
            this.toastSrv.hide();
            observer.next(false);
            this.backendService.showErrPage(res, '初始化失败')
          })
      }
    );
  }

  async initUserInfo(code: string, open_id: string) {

    let customerInfo = await this.backendService.get<{ open_id: string, account_id: number }>('customer_info', { code: code, open_id: open_id }, false).toPromise()
    WXDJ.open_id = customerInfo.open_id;
    WXDJ.account_id = customerInfo.account_id;

    if (customerInfo.account_id == null) {

      let wxConfigParam = await this.backendService.get('wx_get_config', { url: location.href }, false).toPromise();
      wx.config(JSON.parse(wxConfigParam));
      await new Promise(
        (resolve, reject) => {
          wx.ready(() => resolve(true));
          wx.error(err => reject(err.errMsg));
        }
      );

      return new Promise((resolve, reject) => {
        wx.getLocation({
          type: 'wgs84',
          success: result => {
            this.backendService.post('assign_account', { CustomerID: customerInfo.open_id, Longitude: result.longitude, Latitude: result.latitude }, false)
              .subscribe(res => {
                WXDJ.account_id = res;
                resolve(true);
              }, err => {
                WXDJ.account_id = 1;
                resolve(true);
                //reject(err)
              });
          },
          fail: (err: any) => {
            WXDJ.account_id = 1;
            resolve(true);
            //reject(err.errMsg);
          },
          cancel: (err: any) => {
            WXDJ.account_id = 1;
            resolve(true);
            //reject("请选择【同意】获取你的地理位置");
          }
        });
      })
    }
  }
}
