import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ngx-weui';

@Component({
  selector: 'wxdj-entry-main',
  templateUrl: './entry-main.component.html',
  styleUrls: ['./entry-main.component.less']
})
export class EntryMainComponent implements OnInit {

  constructor(
  ) { 
  }

  ngOnInit(): void {
  }

}
