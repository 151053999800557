export class UnifiedResponse<T = any>{
    State: boolean;
    Message?: string;
    Result?: T;

    static GetSuccess<T>(result: T = null, msg: string = null) {
        return {
            State: true,
            Message: msg,
            Result: result
        }
    }
    static GetFail<T>(msg: string = null) {
        return {
            State: false,
            Message: msg,
            Result: null
        }
    }
}

export class OrderItem {
    ID: number;
    Content: string;
    CheckResult: string;
    Flag: string;
    Quantity: number;
    Charge: number;
    ChargeNote: string;
    Photos:string[];
    Title:string;
    Sequence:number;
}

export const WXDJ = {
    inited: false,
    isDebug: false,
    backend_address: "",
    open_id: '',
    account_id: null as number,
}