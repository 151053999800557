import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { UnifiedResponse, WXDJ } from '../common';
import { DialogService, ToptipsService } from 'ngx-weui';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  constructor(
    private http:HttpClient,
    private srv: ToptipsService,
    private dialogSrv: DialogService,
    private router: Router
  ) { }
  
  private handleError(error: HttpErrorResponse) {
    let errMsg = "";
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errMsg = error.error.message;
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errMsg = error.error.ExceptionMessage || error.message;
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(errMsg);
  };

  promptErrMsg(errMsg:string){
    //this.srv.warn(errMsg);
    // this.modal.error({
    //   nzTitle: '系统错误',
    //   nzContent: errMsg
    // });
    this.dialogSrv.show({
      "title":"错误信息",
      "content":errMsg,
      "cancel":undefined,
      "confirm":"知道了"
    }).subscribe();
  }
  showErrPage(detail:string, title:string = "系统异常"){
    this.router.navigate(['/error', { title, detail}]);
  }

  post<T=any>(url,param=null,useErrHandle=true){
    return new Observable<T>(observer=>{
      this.http.post<UnifiedResponse<T>>(`${WXDJ.backend_address}/api/wxdj/${url}`,param)
      .pipe(catchError(this.handleError))
      .subscribe(res=>{
        if(res.State){
          observer.next(res.Result);
        }
        else{
          observer.error(res.Message);
          useErrHandle && this.promptErrMsg(res.Message);
        }
      }, err => {
        observer.error(err);
        useErrHandle && this.promptErrMsg(err);
      }
      ,()=>observer.complete());
    })
  }
  get<T=any>(url,param = null,useErrHandle=true){
    return new Observable<T>(observer=>{
      this.http.get<UnifiedResponse<T>>( `${WXDJ.backend_address}/api/wxdj/${url}`, {params:param})
      .pipe(catchError(this.handleError))
      .subscribe(res=>{
        if(res.State){
          observer.next(res.Result);
        }
        else{
          useErrHandle && this.promptErrMsg(res.Message);
          observer.error(res.Message);
        }
      }, err => {
        observer.error(err);
        useErrHandle && this.promptErrMsg(err);
      },
      ()=>observer.complete());
    })
  }
  executeSql<T=any>(sql:string|string[],useErrHandle=true){
    return this.post<T>('ExecuteSql', {sql:sql}, useErrHandle);
  }
  executeSp<T=any>(spName:string, spParam:any=undefined,useErrHandle=true){
    return this.post<T>('ExecuteSp', {spName:spName, spParam:spParam}, useErrHandle);
  }
}
