import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wxdj-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.less']
})
export class PageErrorComponent implements OnInit {

  title:string;
  detail:string;

  constructor( private route:ActivatedRoute) { 
    this.route.paramMap.subscribe(params=>{
       this.title = params.get('title');
       this.detail = params.get('detail');
    })
  }

  ngOnInit(): void {
  }

}
