import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InitConfigGuard } from './init-config.guard';
import { InitOpenidGuard } from './init-openid.guard';
import { PageErrorComponent } from './page-error/page-error.component';
import { EntryMainComponent } from './entry-main/entry-main.component';
import { PhoneAuthGuard } from './wxdj-phone/phone-auth.guard';

const routes: Routes = [
  {
    path:'',
    canActivateChild:[InitConfigGuard],
    children:[
      {
        path:'',
        canActivate:[InitOpenidGuard],
        loadChildren:()=>import('./wxdj-main/wxdj-main.module').then(m=>m.WxdjMainModule)
      },
      {
        path:'agent',
        loadChildren:()=>import('./wxdj-agent/wxdj-agent.module').then(m=>m.WxdjAgentModule)
      },
      {
        path:'admin',
        loadChildren:()=>import('./wxdj-admin/wxdj-admin.module').then(m=>m.WxdjAdminModule)
      },
      {
        path:'phone',
        canActivate:[PhoneAuthGuard],
        loadChildren:()=>import('./wxdj-phone/wxdj-phone.module').then(m=>m.WxdjAdminPhoneModule)
      },
      {
        path:'error',
        component:PageErrorComponent
      },
      {
        path:'',
        redirectTo:'',
        pathMatch:'full'
      }, 
      // {
      //   path: '**',
      //   redirectTo:'main'
      // }      
    ]
  }
 
];
//, { useHash: false }
@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
