import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { WXDJ } from './common';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AgentService } from './services/agent.service';

@Injectable({
  providedIn: 'root'
})
export class InitConfigGuard implements CanActivateChild {
  constructor(
    private _http: HttpClient,
    private _agentSrv:AgentService
  ) { }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // if (!WXDJ.inited) {
    //   return new Observable<boolean>(observer => {
    //     this._http.get<any>('assets/config.json')
    //       .subscribe(data => {
    //         WXDJ.isDebug = data.isDebug;
    //         WXDJ.backend_address = data.backendEndpoint;
    //         WXDJ.inited = true;
    //         observer.next(true);
    //       }, err => observer.next(false));
    //   })
    // }
    // else {
    //   return true;
    // }
    return this.doInit();
  }

  async doInit() {
    if(WXDJ.inited) return true;
    //let configData = await this._http.get<any>('assets/config.json').toPromise();
    WXDJ.isDebug = false;
    WXDJ.backend_address = 'https://wxdj.site/backend';
    //WXDJ.backend_address = 'http://localhost:9090';
    WXDJ.inited = true;
    WXDJ.account_id = -1;
    return this._agentSrv.init();
  }
}
