<div class="weui-msg">
    <div class="weui-msg__icon-area"><i class="weui-icon-warn weui-icon_msg"></i></div>
    <div class="weui-msg__text-area">
        <h2 class="weui-msg__title">{{title}}</h2>
        <p class="weui-msg__desc"> {{detail}}</p>
    </div>
    <div class="weui-msg__opr-area">
        <p class="weui-btn-area">
            <a href="tel:18108124158" class="weui-btn weui-btn_primary">联系技术支持</a>
            <a href="javascript:WeixinJSBridge.call('closeWindow');" class="weui-btn weui-btn_default">关闭</a>
        </p>
    </div>
    <div class="weui-msg__extra-area">
        <div class="weui-footer">
            <p class="weui-footer__text">Copyright © 微洗到家</p>
        </div>
    </div>
</div>