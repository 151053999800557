import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  agentObj:{[key:number]:{AgentName:string,ContactName:string, ContactPhone:string, ContactAddress:string}} = {};
  agentList:{ID:number, AgentName:string,ContactName:string, ContactPhone:string, ContactAddress:string}[] = [];
  constructor(
    private _backendSvr:BackendService
  ) { 
  }

  getAgentName(id:number){
    return this.agentObj[id]?.AgentName;
  }
  async init(){
    return new Promise<boolean>(resolve=>{
      this._backendSvr.executeSql('select * from [Market] where type = 1')
      .subscribe((res:any[])=>{
        this.agentList = res;
        this.agentObj = {};
        res.forEach(val=>{
          this.agentObj[val.ID] = {
            AgentName : val.AgentName,
            ContactName : val.ContactName,
            ContactPhone : val.ContactPhone,
            ContactAddress : val.ContactAddress
          }
        })
        resolve(true)
      }
      ,error=>{
        resolve(false);
      })
    });
  }
}
