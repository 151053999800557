import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BackendService } from '../services/backend.service';
import { WXDJ } from '../common';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class PhoneAuthGuard implements CanActivate {
  constructor(
    private backendSrv:BackendService
  ){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      WXDJ.open_id = next.queryParamMap.get('openid') || '';

      let phone_account_id = parseInt( sessionStorage.getItem('phone_account_id'));
      if(phone_account_id > 0){
        WXDJ.account_id = phone_account_id;
        return true;
      }
      
      let auth = next.queryParamMap.get('auth') || '';
      return this.backendSrv.executeSql(`select ID from Account where IsEnable = 1 and ContactPhone='${auth}'`)
      .toPromise().then(res=>{
        if(res.length>0){
          WXDJ.account_id = res[0].ID;
          sessionStorage.setItem('phone_account_id', WXDJ.account_id.toString());
          return true;
        }
        else{
          this.backendSrv.showErrPage("身份验证失败");
          return false;
        }
      })
  }
  
}
